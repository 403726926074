import React, { Component } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faDollarSign, faDonate } from "@fortawesome/free-solid-svg-icons";
import { default as Soiree2024Pledges } from "./Pledges";
import { default as Soiree2024CustomPledges } from "./CustomPledges";
import { default as Soiree2024PledgeList } from "./PledgeList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDisplay, faList, faDollar } from "@fortawesome/free-solid-svg-icons";

const weclome_message_barcode = (
  <span className="message info">
    Select your pledge amount then enter your barcode
  </span>
);
const weclome_message_paddle = (
  <span className="message info">
    Select your pledge amount then enter your paddle number
  </span>
);

const menuItems = [
  {
    label: "Add Pledge By Barcode",
    link: "/soiree2024/pledges?type=barcode",
    icon: faDollar,
    welcome: weclome_message_barcode,
  },
  {
    label: "Add Pledge By Paddle Number",
    link: "/soiree2024/pledges?type=paddle_number",
    icon: faDollar,
    welcome: weclome_message_paddle,
  },
  {
    label: "FAN List",
    link: "/soiree2024/pledge-list",
    icon: faList,
    welcome: "",
  },
];

const pledgeButtons = [
  { start: 100, end: 750, label: "$100 to $750", icon: faDonate },
  { start: 1000, end: 2000, label: "$1000 to $2000", icon: faDonate },
  { start: 2500, end: 10000, label: "$2,500 to $10,000", icon: faDonate },
  { start: 12500, end: 50000, label: "$12,500 to $50,000", icon: faDonate },
  { label: "Custom", icon: faDollarSign },
];

class PledgesComponent extends Component {
  constructor(props) {
    super(props);

    const isTest = window.location.hostname.includes('test.');

    this.state = {
      isLoading: false,
      selectedPledge: 0,
      start: pledgeButtons[0].start,
      end: pledgeButtons[0].end,
      allCategories: [],
      categories: [],
      selectedType: 0,
      titlePrefix: isTest? <> [Staff Only, Test]</>: <> [Staff Only]</>
    };
  }

  setSelectedType = (selectedType) => {

    this.setState({
      selectedType,
    });
  };

  setSelectedPledge = (selectedPledge) => {
    const pledge = pledgeButtons[selectedPledge];

    let myDataList = [...this.state.allCategories];

    myDataList = myDataList.filter(
      (category) =>
        category.amount >= pledge.start && category.amount <= pledge.end
    );

    this.setState({
      selectedPledge,
      start: pledge.start,
      end: pledge.end,
      categories: myDataList,
    });
  };

  fetchCategories = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/brenda/pledgeCategories`)
      .then((response) => response.json())
      .then((dataList) => {
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            if (this.state.start && this.state.end) {
              myDataList = myDataList.filter(
                (category) =>
                  category.amount >= this.state.start &&
                  category.amount <= this.state.end
              );
            } else {
              myDataList = myDataList.slice(0, 4);
            }

            this.setState({ allCategories: dataList, categories: myDataList });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchCategories();
      this.setState({ isLoading: false });
    });
  }

  setIsLoading = (status) => {
    this.setState({ isLoading: status });
  };

  render() {
    if (this.state.selectedType === 2) {
      return (
        <>
          <LogoHeader
            selectedType={this.state.selectedType}
            setSelectedType={this.setSelectedType}
            menuItems={menuItems}
          />
          <Soiree2024PledgeList
            selectedType={this.state.selectedType}
            setSelectedType={this.setSelectedType}
          />
        </>
      );
    }

    return (
      <>
        <LogoHeader
          selectedType={this.state.selectedType}
          setSelectedType={this.setSelectedType}
          menuItems={menuItems}
        />
        <main className="add-pledges">
          <section className="container stylization maincont">

            {this.state.start && this.state.end ? (
              <>
                <Soiree2024Pledges
                  setIsLoading={this.setIsLoading}
                  categories={this.state.categories}
                  toast={toast}
                  selectedPledge={this.state.selectedPledge}
                  setSelectedPledge={this.setSelectedPledge}
                  pledgeButtons={pledgeButtons}
                  selectedType={this.state.selectedType}
                  menuItems={menuItems}
                  titlePrefix={this.state.titlePrefix}
                />
              </>
            ) : (
              <>
                <Soiree2024CustomPledges
                  setIsLoading={this.setIsLoading}
                  categories={this.state.categories}
                  toast={toast}
                  selectedPledge={this.state.selectedPledge}
                  setSelectedPledge={this.setSelectedPledge}
                  pledgeButtons={pledgeButtons}
                  selectedType={this.state.selectedType}
                  titlePrefix={this.state.titlePrefix}
                />
              </>
            )}
          </section>

          <ToastContainer />
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default PledgesComponent;