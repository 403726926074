import "./css/App.css";

import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { default as Soiree2024PledgesComponent } from "./Soiree2024/PledgesComponent";
import Auth from "./Auth";

function App() {
  useEffect(() => {
    // Redirect logic
    if (window.location.pathname === "/receipts") {
      window.location.href = "https://hermes.bigsistersbc.ca";
    }
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Soiree2024PledgesComponent />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<Soiree2024PledgesComponent />} />
      </Routes>
    </div>
  );
}

export default App;